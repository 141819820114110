<template>
   <b-card class="sticky top-0">
      <!-- Button: Send Invoice -->
      <b-button
         v-if="etatInvoice !== 'draft'"
         @click.stop.prevent="q_sendMail"
         v-b-modal.modal-mail-sender
         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
         variant="primary"
         class="mb-75"
         block
      >
         <!-- @click="sendFactureEmail" -->
         Envoyer
      </b-button>

      <!-- Button: Print -->
      <b-button
         v-ripple.400="'rgba(186, 191, 199, 0.15)'"
         variant="outline-secondary"
         class="mb-75"
         block
         @click="printInvoice('@page {margin-top: 1cm; margin-bottom: 1cm; }')"
      >
         Imprimer
      </b-button>

      <!-- Button: Print -->
      <b-button
         v-ripple.400="'rgba(186, 191, 199, 0.15)'"
         variant="outline-secondary"
         class="mb-75"
         block
         @click="
            printInvoiceEntete(
               '@page {margin-top: 7.5cm; margin-bottom: 5.5cm; }'
            )
         "
      >
         Avec une entête
      </b-button>

      <!-- Button: Edit -->
      <!-- <b-button
						v-if="!invoiceData.code"
						v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						variant="outline-secondary"
						block
						@click="editFacture"
					>
						Modifier
					</b-button> -->

      <!-- Button: enregistrer -->
      <b-button
         v-if="regler !== true && etatInvoice !== 'draft'"
         v-ripple.400="'rgba(186, 191, 199, 0.15)'"
         variant="primary"
         class="mb-75"
         v-b-modal.modal-billPayment-add
         block
      >
         Règlement
      </b-button>

      <b-button
         v-if="status === 'facture' && etatInvoice === 'draft'"
         v-ripple.400="'rgba(186, 191, 199, 0.15)'"
         variant="primary"
         class="mb-75"
         block
         @click="etatInvoiceFunctions"
      >
         Validate
      </b-button>
      <b-button
         v-if="status === 'facture'"
         v-ripple.400="'rgba(113, 102, 240, 0.15)'"
         variant="outline-primary"
         block
         to="/lists/factures"
      >
         Liste des factures
      </b-button>
      <b-button
         v-if="status === 'devis'"
         v-ripple.400="'rgba(113, 102, 240, 0.15)'"
         variant="outline-primary"
         block
         to="/lists/devis"
      >
         Liste des devis
      </b-button>

      <b-button
         v-ripple.400="'rgba(113, 102, 240, 0.15)'"
         variant="primary"
         block
         :to="status === 'devis' ? {name: 'DevisAdd'}: {name: 'FactureAdd'}"
      >
      {{status === 'devis' ? 'Creation de devis' : 'Creation de  facture'}}
      </b-button>
   </b-card>
</template>

<script>
import { onMounted } from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';
import { VBToggle } from 'bootstrap-vue';
import axios from 'axios';
import URL from '@/views/pages/request';
import html2pdf from 'html2pdf.js';
export default {
   directives: {
      Ripple,
      'b-toggle': VBToggle,
   },
   components: {},

   props: [
      'q_sendMail',
      'etatInvoice',
      'etatInvoiceFunctions',
      'regler',
      'status',
      'idInvoice',
   ],

   setup(props, { root }) {
      /***
		 @BUTTON_PRINT
         @Type window
		 */
      const printInvoice = async (content) => {
         // document.title = '.';

         var opt = {
            margin:[0.25, 0, 0, 0],
            filename: `${props.status === 'devis' ? 'debvis_N' : 'facture_N'}${props.idInvoice}.pdf`,
            image: { type: 'pdf', quality: 0.98 },
            html2canvas: {
               scale: 2,
               dpi: 300,
               letterRendering: true,
               useCORS: true,
            },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
         };

         // New Promise-based usage:
         const getElemntInvoicePrint = document.querySelector(
            '.main_invoice_card'
         );
         // await html2pdf()
         //    .from(getElemntInvoicePrint)
         //    .set(opt)
         //    .save();
         root.$store.commit('qInvoice/HIDDEN_INVOICE_MORE_DETAIL', true, {
            root: true,
         });

          setTimeout(() => {
             window.print();
          }, 100);
      };

      const printInvoiceEntete =  (content) => {
         var opt = {
            margin: [1.5, 0, 1.5, 0],
            padding: 4,
            filename: `${props.status === 'devis' ? 'devis_N' : 'facture_N'}${props.idInvoice}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            enableLinks: true,
         };

         root.$store.commit('qInvoice/HIDDEN_DEFAULT_ENTETE', true, {
            root: true,
         });

         // New Promise-based usage:
         const getElemntInvoicePrint = document.querySelector(
            '.main_invoice_card'
         );
          html2pdf()
            .from(getElemntInvoicePrint)
            .set(opt)
            .save()

            
         
         root.$store.commit('qInvoice/HIDDEN_INVOICE_MORE_DETAIL', false, {
            root: true,
         });


         setTimeout(() => {
            root.$store.commit('qInvoice/HIDDEN_DEFAULT_ENTETE', false, {
            root: true,
         });
         }, 50);

      };

      window.onafterprint = () => {
         document.title = 'Details - Ediqia';
         root.$store.commit('qInvoice/HIDDEN_DEFAULT_ENTETE', false, {
            root: true,
         });
         root.$store.commit('qInvoice/HIDDEN_INVOICE_MORE_DETAIL', false, {
            root: true,
         });
      };

      /***
		 @BUTTON_PRINT
         @Type Window
		 */

      return {
         printInvoice,
         printInvoiceEntete,
      };
   },
};
</script>

<style scoped></style>
